import { useEffect, useRef, useState } from 'react';

const Sound = ({ isMuted }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const clickSoundRef = useRef(null);
  const openSoundRef = useRef(null);
  const notificationSoundRef = useRef(null);
  const categoryHoverRef = useRef(null);
  const linkHoverRef = useRef(null);

  useEffect(() => {
    // Sesleri yükle
    clickSoundRef.current = new Audio('/sound/click.mp3');
    openSoundRef.current = new Audio('/sound/open.mp3');
    notificationSoundRef.current = new Audio('/sound/notification.mp3');
    categoryHoverRef.current = new Audio('/sound/category-hover.mp3');
    linkHoverRef.current = new Audio('/sound/link-hover.mp3');

    // Ses seviyelerini ayarla
    if (clickSoundRef.current) clickSoundRef.current.volume = 0.5;
    if (openSoundRef.current) openSoundRef.current.volume = 0.7;
    if (notificationSoundRef.current) notificationSoundRef.current.volume = 0.6;
    if (categoryHoverRef.current) categoryHoverRef.current.volume = 0.2;
    if (linkHoverRef.current) linkHoverRef.current.volume = 0.2;

    // Seslerin yüklendiğinden emin ol
    Promise.all([
      clickSoundRef.current.load(),
      openSoundRef.current.load(),
      notificationSoundRef.current.load(),
      categoryHoverRef.current.load(),
      linkHoverRef.current.load()
    ]).then(() => {
      setIsLoaded(true);
    }).catch(error => {
      console.error('Ses yükleme hatası:', error);
    });

    // Global ses fonksiyonlarını ayarla
    window.playSound = {
      click: () => {
        if (!isMuted && isLoaded && clickSoundRef.current) {
          clickSoundRef.current.currentTime = 0;
          clickSoundRef.current.play().catch(error => {
            console.error('Ses çalma hatası:', error);
          });
        }
      },
      open: () => {
        if (!isMuted && isLoaded && openSoundRef.current) {
          openSoundRef.current.currentTime = 0;
          openSoundRef.current.play().catch(error => {
            console.error('Ses çalma hatası:', error);
          });
        }
      },
      notification: () => {
        if (!isMuted && isLoaded && notificationSoundRef.current) {
          notificationSoundRef.current.currentTime = 0;
          notificationSoundRef.current.play().catch(error => {
            console.error('Ses çalma hatası:', error);
          });
        }
      },
      categoryHover: () => {
        if (!isMuted && isLoaded && categoryHoverRef.current) {
          categoryHoverRef.current.currentTime = 0;
          categoryHoverRef.current.play().catch(error => {
            console.error('Ses çalma hatası:', error);
          });
        }
      },
      linkHover: () => {
        if (!isMuted && isLoaded && linkHoverRef.current) {
          linkHoverRef.current.currentTime = 0;
          linkHoverRef.current.play().catch(error => {
            console.error('Ses çalma hatası:', error);
          });
        }
      }
    };

    // Cleanup function
    return () => {
      const sounds = [
        clickSoundRef.current,
        openSoundRef.current,
        notificationSoundRef.current,
        categoryHoverRef.current,
        linkHoverRef.current
      ];

      sounds.forEach(sound => {
        if (sound) {
          sound.pause();
          sound = null;
        }
      });

      delete window.playSound;
    };
  }, [isMuted, isLoaded]);

  // Effect for muting/unmuting
  useEffect(() => {
    const sounds = [
      clickSoundRef.current,
      openSoundRef.current,
      notificationSoundRef.current,
      categoryHoverRef.current,
      linkHoverRef.current
    ];

    sounds.forEach(sound => {
      if (sound) {
        if (isMuted) {
          sound.pause();
          sound.currentTime = 0;
        }
      }
    });
  }, [isMuted]);

  return null;
};

export default Sound;