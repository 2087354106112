import React, { useState, useEffect } from 'react';
import SidebarCategories from './components/SidebarCategories';
import axios from 'axios';
import { AnimatePresence } from 'framer-motion';
import Sound from './components/Sound';
import AnimatedLinkCard from './components/AnimatedLinkCard';
import {
  LayoutGrid as Grid,
  Bell,
  Layout,
  Search,
  Box,
  Film,
  Music,
  Code,
  BookOpen,
  Gamepad2,
  Sun,
  Moon,
  MoreVertical,
  User,
  Home,
  Star,
  Zap,
  Share2,
  Volume2,   // Ekledik
  VolumeX    // Ekledik
} from 'lucide-react';
import LoginModal from './components/LoginModal';
import AdminPanel from './components/AdminPanel';

// BackgroundVideo component
const BackgroundVideo = ({ isLightMode }) => {
  const videoRef = React.useRef(null);

  React.useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play().catch(error => {
        console.log("Video autoplay failed:", error);
      });
    }
  }, []);

  return (
    <div className="fixed inset-0 -z-10">
      <video 
        ref={videoRef}
        autoPlay 
        loop 
        muted 
        playsInline
        className="w-full h-full object-cover"
      >
        <source src="/video.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className={`absolute inset-0 backdrop-blur-sm ${
        isLightMode 
          ? 'bg-gradient-to-br from-white/20 via-gray-100/20 to-gray-200/20' 
          : 'bg-gradient-to-br from-blue-500/20 via-purple-500/20 to-red-500/20'
      }`}></div>
    </div>
  );
};

// Main App component
const App = () => {
  // State definitions
  const [isLightMode, setIsLightMode] = useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isAdminPanelOpen, setIsAdminPanelOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [apps, setApps] = useState([]);
  const [categories, setCategories] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [hierarchicalCategories, setHierarchicalCategories] = useState([]);
  const [showNotifications, setShowNotifications] = useState(false);
  const [notifications] = useState([
    { id: 1, message: "Welcome to the app!" },
    { id: 2, message: "New features available" },
    { id: 3, message: "Check out our latest updates" }
  ]);

  // Tema değişikliğini izle ve uygula
  useEffect(() => {
    const root = document.documentElement;
    if (isLightMode) {
      root.classList.add('light-mode');
    } else {
      root.classList.remove('light-mode');
    }
}, [isLightMode]);

  // Icon mapping
  const icons = {
    Layout,
    Search,
    Grid,
    Box,
    Film,
    Music,
    Code,
    Book: BookOpen,
    Game: Gamepad2,
    Home,
    Star,
    Zap
  };

  useEffect(() => {
    const API_URL = process.env.REACT_APP_API_URL || 'https://koswog.com/api';

    const fetchData = async () => {
      try {
        const categoriesResponse = await axios.get(`${API_URL}/categories`, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        });
        setCategories(categoriesResponse.data);
        
        const hierarchicalResponse = await axios.get(`${API_URL}/categories/hierarchical`, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        });
        if (Array.isArray(hierarchicalResponse.data)) {
          setHierarchicalCategories(hierarchicalResponse.data);
        }
    
        const appsResponse = await axios.get(`${API_URL}/apps`, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        });
        
        const appsWithTimer = appsResponse.data.map(app => {
          if (app.hasTimer && app.timerStartAt) {
            const now = new Date();
            const startTime = new Date(app.timerStartAt);
            const elapsed = Math.floor((now - startTime) / 1000);
            const remaining = Math.max(24 * 60 * 60 - elapsed, 0);
            
            return {
              ...app,
              timeLeft: remaining
            };
          }
          return app;
        });
        
        setApps(appsWithTimer);
        
      } catch (error) {
        console.error('Error fetching data:', error.response || error);
      }
    };

    fetchData();
  }, [isAdminPanelOpen]);
  useEffect(() => {
    const handleCategorySelect = (event) => {
      setSelectedCategory(event.detail);
    };
    
    window.addEventListener('selectCategory', handleCategorySelect);
    return () => window.removeEventListener('selectCategory', handleCategorySelect);
  }, []);
  const [isMuted, setIsMuted] = useState(() => {
    const saved = localStorage.getItem('isMuted');
    return saved ? JSON.parse(saved) : false;
  });
  
  // Ses durumunu localStorage'a kaydet
  useEffect(() => {
    localStorage.setItem('isMuted', JSON.stringify(isMuted));
  }, [isMuted]);

  // Search and filtering
  const filteredApps = apps.filter(app => {
    // Arama filtrelemesi
    const matchesSearch = app.name.toLowerCase().includes(searchTerm.toLowerCase());
    
    let matchesCategory = !selectedCategory;
    
    if (selectedCategory) {
      // Seçili kategoriyi bul
      const selectedCat = categories.find(c => c._id === selectedCategory);
      
      if (!selectedCat) {
        console.warn('Selected category not found:', selectedCategory);
        return false;
      }
      
      if (selectedCat.isParent) {
        // Ana kategori seçiliyse, tüm alt kategorileri bul
        const childCategories = categories
          .filter(c => String(c.parent) === String(selectedCategory))
          .map(c => String(c._id));
        
        if (childCategories.length === 0) {
          console.warn('No child categories found for parent:', selectedCategory);
          return false;
        }
        
        // App'in kategori ID'sini güvenli bir şekilde al ve string'e çevir
        const appCategoryId = String(
          typeof app.category === 'object' ? app.category?._id : app.category
        );
        
        // Alt kategorilerden herhangi biriyle eşleşiyor mu kontrol et
        matchesCategory = childCategories.includes(appCategoryId);
        
        // Debug için log
        console.log('Parent category match:', {
          appName: app.name,
          appCategoryId,
          childCategories,
          matches: matchesCategory
        });
        
      } else {
        // Alt kategori seçiliyse direkt eşleştir
        const appCategoryId = String(
          typeof app.category === 'object' ? app.category?._id : app.category
        );
        
        matchesCategory = appCategoryId === String(selectedCategory);
        
        // Debug için log
        console.log('Child category match:', {
          appName: app.name,
          appCategoryId,
          selectedCategory,
          matches: matchesCategory
        });
      }
    }
    
    // Hem arama hem de kategori filtrelerine uymalı
    const matches = matchesSearch && matchesCategory;
    
    // Debug için son kontrol logu
    console.log('Final match for app:', {
      appName: app.name,
      matchesSearch,
      matchesCategory,
      finalMatch: matches
    });
    
    return matches;
  });

  // Handle login/logout
  const handleLogin = () => {
    setIsLoggedIn(true);
    setIsLoginModalOpen(false);
    setIsAdminPanelOpen(true);
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    setIsAdminPanelOpen(false);
  };

  return (
    <div className={`min-h-screen w-full p-4 flex items-center justify-center transition-colors duration-200
      ${isLightMode ? 'bg-gradient-to-br from-white/20 via-gray-100/20 to-gray-200/20' : 
      'bg-gradient-to-br from-blue-500/20 via-purple-500/20 to-red-500/20'}`}>
        <Sound isMuted={isMuted} />
      <BackgroundVideo isLightMode={isLightMode} />

      <LoginModal 
        isOpen={isLoginModalOpen}
        onClose={() => setIsLoginModalOpen(false)}
        onLogin={handleLogin}
        isLightMode={isLightMode}
      />

      <AdminPanel 
        isOpen={isAdminPanelOpen}
        onClose={() => setIsAdminPanelOpen(false)}
        onLogout={handleLogout}
        isLightMode={isLightMode}
      />
      
      <div className={`w-full max-w-[1250px] h-[90vh] max-h-[860px] rounded-xl backdrop-blur-xl 
        ${isLightMode 
          ? 'bg-white/60 border-gray-200/50' 
          : 'bg-[#10121b]/60 border-white/10'
        }
        flex flex-col overflow-hidden relative shadow-2xl border transition-colors duration-200`}>
        
        {/* Header */}
        <header className={`h-[58px] px-6 flex items-center border-b transition-colors duration-200
          ${isLightMode 
            ? 'border-gray-200/50 bg-white/10' 
            : 'border-white/10 bg-black/10'
          }`}>
          <div className="flex gap-2 mr-8">
            <div className="w-3 h-3 rounded-full bg-[#f96057] hover:opacity-80 cursor-pointer transition-opacity"></div>
            <div className="w-3 h-3 rounded-full bg-[#f8ce52] hover:opacity-80 cursor-pointer transition-opacity"></div>
            <div className="w-3 h-3 rounded-full bg-[#5fcf65] hover:opacity-80 cursor-pointer transition-opacity"></div>
          </div>

          <nav className="flex items-center">
            <button className={`px-6 py-5 transition-colors ${
              isLightMode 
                ? 'text-gray-800 border-gray-800' 
                : 'text-white/90 border-white/90'
              } border-b-2 hover:text-gray-900`}>
              Sites
            </button>
            <button className={`px-6 py-5 relative transition-colors ${
              isLightMode 
                ? 'text-gray-600 hover:text-gray-800' 
                : 'text-white/60 hover:text-white/90'
            }`}>
              Market
              <span className="absolute right-4 top-4 w-1.5 h-1.5 rounded-full bg-[#3a6df0]"></span>
            </button>
          </nav>

          <div className="flex-1 max-w-[400px] mx-auto">
            <div className="relative">
              <Search className={`absolute left-3 top-1/2 transform -translate-y-1/2 w-4 h-4 ${
                isLightMode ? 'text-gray-400' : 'text-white/40'
              }`} />
              <input 
                type="text"
                placeholder="Search apps..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className={`w-full h-10 rounded-lg pl-10 pr-4 border transition-colors
                  ${isLightMode 
                    ? 'bg-white/20 text-gray-800 border-gray-200/50 placeholder-gray-400 focus:border-gray-300' 
                    : 'bg-black/20 text-white border-white/10 placeholder-white/40 focus:border-white/20'
                  }`}
              />
            </div>
          </div>

          <div className="flex items-center ml-auto">
            <div className="relative">
              <button
                onClick={() => {
                  window.playSound?.notification();
                  setShowNotifications(!showNotifications)
                }}
                className="relative"
              >
                <Bell className={`w-6 h-6 cursor-pointer transition-colors ${
                  isLightMode 
                    ? 'text-gray-600 hover:text-gray-800' 
                    : 'text-white/80 hover:text-white'
                }`} />
                <span className="absolute -top-1 -right-1 w-4 h-4 bg-[#3a6df0] rounded-full flex items-center justify-center text-xs text-white">
                  {notifications.length}
                </span>
              </button>

              {showNotifications && (
                <div className={`absolute right-0 mt-2 w-64 rounded-lg shadow-lg py-2 z-10 ${
                  isLightMode 
                    ? 'bg-white border-gray-200' 
                    : 'bg-[#1a1c30] border-white/10'
                } border`}>
                  {notifications.map(notification => (
                    <div key={notification.id} className={`px-4 py-2 ${
                      isLightMode 
                        ? 'text-gray-700 hover:bg-gray-100' 
                        : 'text-white/80 hover:bg-white/10'
                    }`}>
                      {notification.message}
                    </div>
                  ))}
                </div>
              )}
            </div>
            
            <button 
              onClick={() => setIsLoginModalOpen(true)}
              className={`w-8 h-8 rounded-full flex items-center justify-center ml-4 transition-colors ${
                isLightMode 
                  ? 'bg-gray-200/50 hover:bg-gray-200' 
                  : 'bg-white/10 hover:bg-white/20'
              }`}
            >
              <User size={18} className={isLightMode ? "text-gray-600" : "text-white/80"} />
            </button>
          </div>
        </header>

        <div className="flex flex-1 overflow-hidden">
          {/* Left Sidebar */}
          <aside className={`w-48 overflow-auto flex-shrink-0 border-r transition-colors duration-200
            ${isLightMode 
              ? 'border-gray-200/50 bg-white/10' 
              : 'border-white/10 bg-black/10'
            }`}>
            <SidebarCategories 
              hierarchicalCategories={hierarchicalCategories}
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
              isLightMode={isLightMode}
            />
          </aside>

          {/* Main Content */}
          <main className="flex-1 p-6 overflow-auto">
            <section>
              <h2 className={`text-sm font-medium mb-4 ${
                isLightMode ? 'text-gray-500' : 'text-white/60'
              }`}>
                {selectedCategory 
                  ? categories.find(c => c._id === selectedCategory)?.name
                  : 'All Apps'}
              </h2>
              <AnimatePresence>
  {filteredApps.map((app) => (
    <AnimatedLinkCard 
      key={app._id} 
      app={app} 
      categories={categories}
      isLightMode={isLightMode}
    />
  ))}
</AnimatePresence>
            </section>
          </main>
        </div>
      </div>
{/* Sound toggle button */}
<button
  onClick={() => setIsMuted(!isMuted)}
  className={`fixed bottom-8 right-20 w-10 h-10 backdrop-blur-md rounded-full 
    flex items-center justify-center shadow-lg transition-colors border z-50
    ${isLightMode 
      ? 'bg-gray-200/50 hover:bg-gray-200 border-gray-200' 
      : 'bg-white/10 hover:bg-white/20 border-white/20'
    }`}
>
  {isMuted ? (
    <VolumeX size={18} className={isLightMode ? "text-gray-800" : "text-white"} />
  ) : (
    <Volume2 size={18} className={isLightMode ? "text-gray-800" : "text-white"} />
  )}
</button>
      {/* Theme toggle button */}
      <button
        onClick={() => setIsLightMode(!isLightMode)}
        className={`fixed bottom-8 right-8 w-10 h-10 backdrop-blur-md rounded-full 
          flex items-center justify-center shadow-lg transition-colors border z-50
          ${isLightMode 
            ? 'bg-gray-200/50 hover:bg-gray-200 border-gray-200' 
            : 'bg-white/10 hover:bg-white/20 border-white/20'
          }`}
      >
        {isLightMode ? (
          <Moon size={18} className="text-gray-800" />
        ) : (
          <Sun size={18} className="text-white" />
        )}
      </button>
    </div>
  );
};

export default App;