// src/components/LoginModal.js
import React, { useState } from 'react';
import axios from 'axios';
const LoginModal = ({ isOpen, onClose, onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    
    try {
      const response = await axios.post('https://koswog.com/api/login', { // https kullandığınızdan emin olun
        username,
        password
      }, {
        withCredentials: true, // Bu satırı ekleyin
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      });
      
      console.log('Sunucu yanıtı:', response.data); // Debug log

      if (response.data.success) {
        onLogin(response.data.user);
        onClose();
      } else {
        setError(response.data.message || 'Giriş başarısız');
      }
    } catch (error) {
      console.error('Login hatası:', error.response?.data || error);
      setError(error.response?.data?.message || 'Bir hata oluştu');
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50">
      <div className="bg-[#1a1c30] rounded-xl p-6 w-96 shadow-xl border border-white/10">
        <h2 className="text-white/90 text-xl mb-4">Admin Login</h2>
        {error && (
          <div className="bg-red-500/20 border border-red-500/50 text-red-500 p-3 rounded-lg mb-4">
            {error}
          </div>
        )}
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <input
              type="text"
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="w-full bg-black/20 rounded-lg p-3 text-white border border-white/10"
            />
          </div>
          <div>
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full bg-black/20 rounded-lg p-3 text-white border border-white/10"
            />
          </div>
          <div className="flex justify-end gap-2">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 rounded-lg bg-white/10 text-white hover:bg-white/20"
            >
              İptal
            </button>
            <button
              type="submit"
              className="px-4 py-2 rounded-lg bg-[#3a6df0] text-white hover:bg-[#3461d1]"
            >
              Giriş Yap
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginModal;