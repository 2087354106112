import React from 'react';
import { LayoutGrid as Grid } from 'lucide-react';

const SidebarCategories = ({ 
  hierarchicalCategories, 
  selectedCategory, 
  setSelectedCategory 
}) => {
  const getCategoryColorByIndex = (index) => {
    const colors = [
      {
        background: 'from-rose-500/10 to-pink-500/10',
        activeBackground: 'from-rose-500/20 to-pink-500/20',
        border: 'border-rose-500',
        line: 'bg-gradient-to-b from-rose-500/50 to-pink-500/50'
      },
      {
        background: 'from-blue-500/10 to-cyan-500/10',
        activeBackground: 'from-blue-500/20 to-cyan-500/20',
        border: 'border-blue-500',
        line: 'bg-gradient-to-b from-blue-500/50 to-cyan-500/50'
      },
      {
        background: 'from-emerald-500/10 to-green-500/10',
        activeBackground: 'from-emerald-500/20 to-green-500/20',
        border: 'border-emerald-500',
        line: 'bg-gradient-to-b from-emerald-500/50 to-green-500/50'
      },
      {
        background: 'from-orange-500/10 to-amber-500/10',
        activeBackground: 'from-orange-500/20 to-amber-500/20',
        border: 'border-orange-500',
        line: 'bg-gradient-to-b from-orange-500/50 to-amber-500/50'
      },
      {
        background: 'from-violet-500/10 to-purple-500/10',
        activeBackground: 'from-violet-500/20 to-purple-500/20',
        border: 'border-violet-500',
        line: 'bg-gradient-to-b from-violet-500/50 to-purple-500/50'
      }
    ];
    return colors[index % colors.length];
  };

  const renderCategory = (category, isChild = false, parentCategory = null, index) => {
    const isSelected = selectedCategory === category._id;
    const colorStyle = getCategoryColorByIndex(index);
    const isParentActive = parentCategory && 
      (selectedCategory === parentCategory._id || 
       parentCategory.children?.some(c => c._id === selectedCategory));

    return (
      <button
        onClick={() => {
          window.playSound?.click();
          setSelectedCategory(category._id);
        }}
        onMouseEnter={() => {
          window.playSound?.categoryHover();  // Kategori hover sesi
        }}
        className={`w-full flex items-center px-3 py-2.5 relative transition-all duration-300 group
          ${isChild ? 'pl-8' : 'font-medium'}
          ${isSelected 
            ? `bg-gradient-to-r ${colorStyle.activeBackground} backdrop-blur-sm border-l-2 ${colorStyle.border}` 
            : isParentActive && !isChild
              ? `bg-gradient-to-r ${colorStyle.background} backdrop-blur-sm`
              : `hover:bg-gradient-to-r hover:${colorStyle.background}`
          }`}
      >
        {isChild && (
          <div className={`absolute left-5 top-0 bottom-0 w-px ${colorStyle.line}
            before:content-[""] before:absolute before:w-2 before:h-px before:top-1/2 before:-translate-y-1/2 
            before:left-0 ${isParentActive ? 'opacity-100' : 'opacity-40'}`} 
          />
        )}

        <div className="w-6 h-6 flex items-center justify-center">
          {category.iconUrl ? (
            <img 
              src={category.iconUrl} 
              alt={category.name}
              className={`w-5 h-5 object-cover transition-opacity
                ${isSelected || isParentActive ? 'opacity-100' : 'opacity-70 group-hover:opacity-100'}`}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = '';
              }}
            />
          ) : (
            <Grid size={18} className="text-white/60 group-hover:text-white/90" />
          )}
        </div>
        <span className={`ml-3 text-sm truncate ${
          isSelected || isParentActive ? 'text-white' : 'text-white/70 group-hover:text-white'
        }`}>
          {category.name}
        </span>
      </button>
    );
  };

  const renderParentWithChildren = (parentCategory, index) => {
    const childCategories = parentCategory.children || [];
    const isParentActive = selectedCategory === parentCategory._id || 
      childCategories.some(child => child._id === selectedCategory);
    
    return (
      <div key={parentCategory._id} className={`mb-2 rounded-lg overflow-hidden
        ${isParentActive ? 'bg-black/20 backdrop-blur-sm' : ''}`}>
        {renderCategory(parentCategory, false, null, index)}
        {childCategories.map(child => 
          renderCategory(child, true, parentCategory, index)
        )}
      </div>
    );
  };

  return (
    <div className="flex flex-col h-full py-2">
      <button
        onClick={() => {
          window.playSound?.click();
          setSelectedCategory(null);
        }}
        onMouseEnter={() => {
          window.playSound?.categoryHover(); // hover -> categoryHover
        }}
        className={`w-full flex items-center px-3 py-2.5 transition-all duration-300 group
          ${!selectedCategory 
            ? 'bg-gradient-to-r from-gray-500/20 to-gray-400/20 backdrop-blur-sm border-l-2 border-gray-500' 
            : 'hover:bg-white/5'}`}
      >
        <div className="w-6 h-6 flex items-center justify-center">
          <Grid size={18} className={`${
            !selectedCategory ? 'text-white' : 'text-white/60 group-hover:text-white/90'
          }`} />
        </div>
        <span className={`ml-3 text-sm font-medium ${
          !selectedCategory ? 'text-white' : 'text-white/70 group-hover:text-white'
        }`}>
          All Links
        </span>
      </button>

      <div className="mt-2 space-y-2">
        {hierarchicalCategories.map((category, index) => 
          renderParentWithChildren(category, index)
        )}
      </div>
    </div>
  );
};

export default SidebarCategories;