import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { X, PanelsTopLeft, Edit, Trash2 } from 'lucide-react';
import Toast from './Toast';

// Yardımcı bileşenler
const ImagePreview = ({ url }) => {
  const [isValid, setIsValid] = useState(true);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!url) return;
    const img = new Image();
    img.onload = () => {
      setIsValid(true);
      setLoading(false);
    };
    img.onerror = () => {
      setIsValid(false);
      setLoading(false);
    };
    img.src = url;
  }, [url]);

  if (!url) return null;
  if (loading) return <div className="text-white/60">Yükleniyor...</div>;
  if (!isValid) return <div className="text-red-500">Geçersiz resim URL'si!</div>;

  return (
    <div className="mt-2">
      <img src={url} alt="Preview" className="w-16 h-16 object-cover rounded" />
    </div>
  );
};

const FormInput = ({ type = "text", placeholder, value, onChange, className = "", required = false }) => (
  <input
    type={type}
    placeholder={placeholder}
    value={value}
    onChange={onChange}
    required={required}
    className={`w-full bg-black/20 rounded-lg p-3 text-white border border-white/10 placeholder-white/40 ${className}`}
  />
);

const Button = ({ onClick, color = "blue", children, type = "button", className = "" }) => {
  const colors = {
    blue: "bg-[#3a6df0] hover:bg-[#3461d1]",
    red: "text-red-500 hover:text-red-400",
    gray: "bg-white/10 hover:bg-white/20",
  };

  return (
    <button
      type={type}
      onClick={onClick}
      className={`px-3 py-1.5 text-white rounded-lg transition-colors ${colors[color]} ${className}`}
    >
      {children}
    </button>
  );
};

const AdminPanel = ({ isOpen, onClose, onLogout }) => {
  // Initial states
  const initialCategoryState = {
    name: '',
    icon: '',
    iconUrl: '',
    title: '',
    parent: null,
    isParent: false,
    order: 0
  };

  const initialAppState = {
    name: '',
    category: '',
    website: {
      url: '',
      status: true
    },
    logo: '',
    link: '',
    additionalButtons: [],
    backgroundImage: ''
  };

  // States
  const [categories, setCategories] = useState([]);
  const [apps, setApps] = useState([]);
  const [toast, setToast] = useState({ show: false, message: '', type: 'success' }); 
  const [editingCategory, setEditingCategory] = useState(null);
  const [editingApp, setEditingApp] = useState(null);
  const [newCategory, setNewCategory] = useState(initialCategoryState);
  const [newApp, setNewApp] = useState(initialAppState);
  const [selectedCategory, setSelectedCategory] = useState('');
  // Fetch data
  const fetchData = async () => {
    try {
      const [categoriesRes, appsRes] = await Promise.all([
        
        axios.get('/api/categories'),  // https://koswog.com/api/categories yerine
        axios.get('/api/apps')         // https://koswog.com/api/apps yerine
      ]);
  
      const processedCategories = categoriesRes.data.map(category => {
        if (category.parent && typeof category.parent === 'string') {
          const parentCategory = categoriesRes.data.find(c => c._id === category.parent);
          return { ...category, parent: parentCategory };
        }
        return category;
      });
  
      setCategories(processedCategories);
      setApps(appsRes.data);
    } catch (error) {
      console.error('Veri yükleme hatası:', error);
    }
  };

  useEffect(() => {
    if (isOpen) {
      fetchData();
    }
  }, [isOpen]);

  // Handle form submits and delete operations
  const handleAppSubmit = async (e) => {
    e.preventDefault();
    
    if (!newApp.name.trim()) {
      setToast({ show: true, message: 'Uygulama adı zorunludur', type: 'error' });
      setTimeout(() => setToast({ show: false, message: '', type: 'success' }), 2000);
      return;
    }
  
    if (!newApp.website?.url.trim()) {
      setToast({ show: true, message: 'Website URL zorunludur', type: 'error' });
      setTimeout(() => setToast({ show: false, message: '', type: 'success' }), 2000);
      return;
    }
  
    if (!selectedCategory) {
      setToast({ show: true, message: 'Kategori seçimi zorunludur', type: 'error' });
      setTimeout(() => setToast({ show: false, message: '', type: 'success' }), 2000);
      return;
    }
  
    try {
      const finalData = {
        name: newApp.name.trim(),
        logo: newApp.logo || '',
        link: newApp.link || '',
        additionalButtons: newApp.additionalButtons || [],
        backgroundImage: newApp.backgroundImage || '',
        website: {
          url: newApp.website.url.trim(),
          status: newApp.website.status
        },
        category: selectedCategory
      };
  
      const response = await axios.post('https://koswog.com/api/apps', finalData);
      
      if (response.data) {
        setToast({ show: true, message: 'Uygulama başarıyla eklendi', type: 'success' });
        setTimeout(() => setToast({ show: false, message: '', type: 'success' }), 2000);
        setNewApp(initialAppState);
        setSelectedCategory('');
        await fetchData();
      }
    } catch (error) {
      setToast({ 
        show: true, 
        message: error.response?.data?.message || 'Bir hata oluştu',
        type: 'error'
      });
      setTimeout(() => setToast({ show: false, message: '', type: 'success' }), 2000);
    }
  };
  const handleAppUpdate = async (e, formData) => {
    e.preventDefault();
    try {
      await axios.put(`https://koswog.com/api/apps/${formData._id}`, formData);
      await fetchData();
      setEditingApp(null);
      setToast({ show: true, message: 'Uygulama başarıyla güncellendi', type: 'success' });
      setTimeout(() => setToast({ show: false, message: '', type: 'success' }), 2000);
    } catch (error) {
      setToast({ 
        show: true, 
        message: error.response?.data?.message || 'Güncelleme sırasında bir hata oluştu',
        type: 'error'
      });
      setTimeout(() => setToast({ show: false, message: '', type: 'success' }), 2000);
    }
  };

  const handleCategorySubmit = async (e, isEditing = false) => {
    e.preventDefault();
    
    const formData = isEditing ? editingCategory : newCategory;
    
    if (!formData.name.trim()) {
      setToast({ show: true, message: 'Kategori adı zorunludur', type: 'error' });
      setTimeout(() => setToast({ show: false, message: '', type: 'success' }), 2000);
      return;
    }
  
    try {
      const endpoint = isEditing ? `categories/${editingCategory._id}` : 'categories';
      const method = isEditing ? 'put' : 'post';
      
      const response = await axios[method](`https://koswog.com/api/${endpoint}`, formData);
      
      if (response.data) {
        setToast({ 
          show: true, 
          message: `Kategori başarıyla ${isEditing ? 'güncellendi' : 'eklendi'}`,
          type: 'success'
        });
        setTimeout(() => setToast({ show: false, message: '', type: 'success' }), 2000);
        
        if (isEditing) {
          setEditingCategory(null);
        } else {
          setNewCategory(initialCategoryState);
        }
        await fetchData();
      }
    } catch (error) {
      setToast({ 
        show: true, 
        message: error.response?.data?.message || 'Bir hata oluştu',
        type: 'error'
      });
      setTimeout(() => setToast({ show: false, message: '', type: 'success' }), 2000);
    }
  };

  const handleDelete = async (id, type) => {
    if (!window.confirm('Bu öğeyi silmek istediğinizden emin misiniz?')) {
      return;
    }
    
    try {
      if (type === 'categories') {
        const category = categories.find(c => c._id === id);
        
        if (!category) {
          setToast({ show: true, message: 'Kategori bulunamadı!', type: 'error' });
          setTimeout(() => setToast({ show: false, message: '', type: 'success' }), 2000);
          return;
        }
  
        if (category.isParent) {
          const childCategories = categories.filter(c => c.parent?._id === id);
          if (childCategories.length > 0) {
            setToast({ 
              show: true, 
              message: 'Bu kategori alt kategorilere sahip. Önce alt kategorileri silin.',
              type: 'error'
            });
            setTimeout(() => setToast({ show: false, message: '', type: 'success' }), 2000);
            return;
          }
        }
  
        const appsInCategory = apps.filter(app => app.category === id);
        if (appsInCategory.length > 0) {
          setToast({ 
            show: true, 
            message: 'Bu kategoride uygulamalar var. Önce uygulamaları silmelisiniz.',
            type: 'error'
          });
          setTimeout(() => setToast({ show: false, message: '', type: 'success' }), 2000);
          return;
        }
      }
  
      await axios.delete(`https://koswog.com/api/${type}/${id}`);
      await fetchData();
      setToast({ show: true, message: 'Başarıyla silindi', type: 'success' });
      setTimeout(() => setToast({ show: false, message: '', type: 'success' }), 2000);
    } catch (error) {
      setToast({ 
        show: true, 
        message: error.response?.data?.message || 'Silme işlemi başarısız',
        type: 'error'
      });
      setTimeout(() => setToast({ show: false, message: '', type: 'success' }), 2000);
    }
  };
  const renderCategoryForm = (isEditing = false) => {
    const formData = isEditing ? editingCategory : newCategory;
    const setFormData = isEditing ? setEditingCategory : setNewCategory;
  
    return (
      <div className="mb-8">
        <h3 className="text-white/80 text-lg mb-4">
          {isEditing ? 'Kategoriyi Düzenle' : 'Yeni Kategori Ekle'}
        </h3>
        <form
          onSubmit={(e) => handleCategorySubmit(e, isEditing)}
          className="space-y-4"
        >
          <FormInput
            placeholder="Kategori Adı"
            value={formData.name}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            required
          />
          <FormInput
            placeholder="İkon"
            value={formData.icon}
            onChange={(e) => setFormData({ ...formData, icon: e.target.value })}
          />
          <div className="space-y-2">
            <FormInput
              placeholder="İkon URL (resim adresi)"
              value={formData.iconUrl}
              onChange={(e) => setFormData({ ...formData, iconUrl: e.target.value })}
            />
            <ImagePreview url={formData.iconUrl} />
          </div>
          <FormInput
            placeholder="Başlık"
            value={formData.title}
            onChange={(e) => setFormData({ ...formData, title: e.target.value })}
          />
          <div className="flex items-center gap-2">
            <input
              type="checkbox"
              checked={formData.isParent}
              onChange={(e) => setFormData({ ...formData, isParent: e.target.checked })}
              className="w-5 h-5 rounded border-white/10 bg-black/20"
            />
            <span className="text-white/80">Üst Kategori mi?</span>
          </div>
          {!formData.isParent && (
            <select
              value={formData.parent || ''}
              onChange={(e) => setFormData({ ...formData, parent: e.target.value })}
              className="w-full bg-black/20 rounded-lg p-3 text-white border border-white/10"
              required
            >
              <option value="">Üst Kategori Seçin</option>
              {categories
                .filter((c) => c.isParent)
                .map((parentCategory) => (
                  <option key={parentCategory._id} value={parentCategory._id}>
                    {parentCategory.name}
                  </option>
                ))}
            </select>
          )}
          <FormInput
            type="number"
            placeholder="Sıralama (0-99)"
            value={formData.order}
            onChange={(e) => setFormData({ ...formData, order: parseInt(e.target.value) || 0 })}
          />
          <div className="flex gap-2">
            <Button type="submit" color="blue">
              {isEditing ? 'Kategoriyi Güncelle' : 'Kategori Ekle'}
            </Button>
            {isEditing && (
              <Button onClick={() => setEditingCategory(null)} color="gray">
                İptal
              </Button>
            )}
          </div>
        </form>
      </div>
    );
  };


  // App edit modal component

// handleDelete fonksiyonundan sonra, AppEditModal'dan önce bu fonksiyonları ekleyin:
  // App form render
  const renderAppForm = () => (
    <div className="mb-8">
      <h3 className="text-white/80 text-lg mb-4">Yeni Uygulama Ekle</h3>
      <form onSubmit={handleAppSubmit} className="space-y-4">
        <FormInput
          placeholder="Uygulama Adı *"
          value={newApp.name}
          onChange={(e) => setNewApp({ ...newApp, name: e.target.value })}
          required
        />
  
        <FormInput
          placeholder="Logo URL"
          value={newApp.logo}
          onChange={(e) => setNewApp({ ...newApp, logo: e.target.value })}
        />
  
        <FormInput
          placeholder="Logo Link"
          value={newApp.link}
          onChange={(e) => setNewApp({ ...newApp, link: e.target.value })}
        />
  
        <div className="space-y-4">
          <div className="flex justify-between items-center">
            <h4 className="text-white/70">Ek Butonlar</h4>
            <Button 
              onClick={() => setNewApp(prev => ({
                ...prev,
                additionalButtons: [...prev.additionalButtons, { logo: '', link: '' }]
              }))}
              color="blue"
              className="text-sm"
            >
              Buton Ekle
            </Button>
          </div>
          
          {newApp.additionalButtons.map((button, index) => (
            <div key={index} className="space-y-2 p-4 bg-black/10 rounded-lg">
              <FormInput
                placeholder="Logo URL"
                value={button.logo}
                onChange={(e) => {
                  const updated = [...newApp.additionalButtons];
                  updated[index].logo = e.target.value;
                  setNewApp({ ...newApp, additionalButtons: updated });
                }}
              />
              <FormInput
                placeholder="Link"
                value={button.link}
                onChange={(e) => {
                  const updated = [...newApp.additionalButtons];
                  updated[index].link = e.target.value;
                  setNewApp({ ...newApp, additionalButtons: updated });
                }}
              />
              <Button
                onClick={() => {
                  const updated = newApp.additionalButtons.filter((_, i) => i !== index);
                  setNewApp({ ...newApp, additionalButtons: updated });
                }}
                color="red"
                className="text-sm"
              >
                Butonu Kaldır
              </Button>
            </div>
          ))}
        </div>
  
        <div className="space-y-2">
          <FormInput
            placeholder="Arkaplan Resmi URL"
            value={newApp.backgroundImage}
            onChange={(e) => setNewApp({ ...newApp, backgroundImage: e.target.value })}
          />
          {newApp.backgroundImage && (
            <div className="relative h-32 rounded-lg overflow-hidden">
              <img 
                src={newApp.backgroundImage} 
                alt="Arkaplan önizleme" 
                className="w-full h-full object-cover"
              />
              <div className="absolute inset-0 bg-black/40" />
            </div>
          )}
        </div>
  
        <FormInput
          placeholder="Website URL *"
          value={newApp.website.url}
          onChange={(e) => setNewApp({
            ...newApp,
            website: { ...newApp.website, url: e.target.value }
          })}
          required
        />
  
        <div className="flex items-center gap-2">
          <input
            type="checkbox"
            checked={newApp.website.status}
            onChange={(e) => setNewApp({
              ...newApp,
              website: { ...newApp.website, status: e.target.checked }
            })}
            className="w-5 h-5 rounded border-white/10 bg-black/20"
          />
          <span className="text-white/80">Website Aktif</span>
        </div>
  
        <select
          value={selectedCategory}
          onChange={(e) => setSelectedCategory(e.target.value)}
          className="w-full bg-black/20 rounded-lg p-3 text-white border border-white/10"
          required
        >
          <option value="">Kategori Seçin *</option>
          {categories
            .filter(c => !c.isParent)
            .map(category => {
              const parentCategory = categories.find(c => c._id === category.parent?._id);
              return (
                <option key={category._id} value={category._id}>
                  {parentCategory ? `${parentCategory.name} > ${category.name}` : category.name}
                </option>
              );
            })}
        </select>
  
        <Button type="submit" color="blue">Uygulama Ekle</Button>
      </form>
    </div>
  );
  // Categories render
  const renderCategories = () => {
    const parentCategories = categories.filter(cat => cat.isParent);
  
    return (
      <div className="space-y-4">
        {parentCategories.map(parent => {
          const childCategories = categories.filter(cat => 
            cat.parent?._id === parent._id || cat.parent === parent._id
          );
          
          return (
            <div key={parent._id} className="bg-black/10 rounded-lg">
              <div className="bg-black/20 p-3 flex items-center justify-between">
                <div className="flex items-center gap-2">
                  {parent.iconUrl ? (
                    <img 
                      src={parent.iconUrl} 
                      alt={parent.name}
                      className="w-6 h-6 object-cover rounded"
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = '';
                      }}
                    />
                  ) : (
                    <PanelsTopLeft className="w-6 h-6 text-white/60" />
                  )}
                  <div>
                    <span className="text-white/90 font-medium">{parent.name}</span>
                    {parent.title && (
                      <span className="text-white/50 text-sm ml-2">({parent.title})</span>
                    )}
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <button
                    onClick={() => setEditingCategory(parent)}
                    className="px-3 py-1 text-blue-400 hover:text-blue-300 transition-colors text-sm"
                  >
                    Düzenle
                  </button>
                  <button
                    onClick={() => handleDelete(parent._id, 'categories')}
                    className="px-3 py-1 text-red-500 hover:text-red-400 transition-colors text-sm"
                  >
                    Sil
                  </button>
                </div>
              </div>
  
              <div className="p-2">
                {childCategories.length > 0 ? (
                  <div className="space-y-2">
                    {childCategories.map(child => (
                      <div 
                        key={child._id} 
                        className="ml-4 flex items-center justify-between p-2 bg-black/10 rounded-lg border border-white/5"
                      >
                        <div className="flex items-center gap-2">
                          {child.iconUrl ? (
                            <img 
                              src={child.iconUrl} 
                              alt={child.name}
                              className="w-5 h-5 object-cover rounded"
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = '';
                              }}
                            />
                          ) : (
                            <PanelsTopLeft className="w-5 h-5 text-white/60" />
                          )}
                          <div>
                            <span className="text-white/80">{child.name}</span>
                            {child.title && (
                              <span className="text-white/40 text-sm ml-2">({child.title})</span>
                            )}
                          </div>
                        </div>
                        <div className="flex items-center gap-2">
                          <button
                            onClick={() => setEditingCategory(child)}
                            className="px-2 py-1 text-blue-400 hover:text-blue-300 transition-colors text-sm"
                          >
                            Düzenle
                          </button>
                          <button
                            onClick={() => handleDelete(child._id, 'categories')}
                            className="px-2 py-1 text-red-500 hover:text-red-400 transition-colors text-sm"
                          >
                            Sil
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="ml-4 py-2 px-3 text-white/40 text-sm italic">
                    Alt kategori bulunmuyor
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    );
  };
  const AppEditModal = ({ app, onClose, onSave }) => {
    const [formData, setFormData] = useState({
      ...app,
      category: app.category._id || app.category // Kategori ID'sini otomatik seç
    });

    const handleSubmit = async (e) => {
      e.preventDefault();
      try {
        await handleAppUpdate(e, formData);
        onSave();
      } catch (error) {
        console.error('App güncelleme hatası:', error);
      }
    };
    return (
      <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50">
        <div className="bg-[#1a1c30] rounded-xl p-6 w-[600px] max-h-[80vh] overflow-y-auto">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-white/90 text-lg">Uygulama Düzenle</h3>
            <button onClick={onClose} className="text-white/60 hover:text-white/80">
              <X size={24} />
            </button>
          </div>

          <form onSubmit={handleSubmit} className="space-y-4">
            <FormInput
              placeholder="Uygulama Adı *"
              value={formData.name}
              onChange={(e) => setFormData({ ...formData, name: e.target.value })}
              required
            />

            <FormInput
              placeholder="Logo URL"
              value={formData.logo}
              onChange={(e) => setFormData({ ...formData, logo: e.target.value })}
            />
            {formData.logo && <ImagePreview url={formData.logo} />}

            <FormInput
              placeholder="Logo Link"
              value={formData.link}
              onChange={(e) => setFormData({ ...formData, link: e.target.value })}
            />

            <div className="space-y-4">
              <div className="flex justify-between items-center">
                <h4 className="text-white/70">Ek Butonlar</h4>
                <Button
                  onClick={() => setFormData(prev => ({
                    ...prev,
                    additionalButtons: [...(prev.additionalButtons || []), { logo: '', link: '' }]
                  }))}
                  color="blue"
                  className="text-sm"
                >
                  Buton Ekle
                </Button>
              </div>

              {formData.additionalButtons?.map((button, index) => (
                <div key={index} className="space-y-2 p-4 bg-black/10 rounded-lg">
                  <FormInput
                    placeholder="Logo URL"
                    value={button.logo}
                    onChange={(e) => {
                      const updated = [...formData.additionalButtons];
                      updated[index].logo = e.target.value;
                      setFormData({ ...formData, additionalButtons: updated });
                    }}
                  />
                  <FormInput
                    placeholder="Link"
                    value={button.link}
                    onChange={(e) => {
                      const updated = [...formData.additionalButtons];
                      updated[index].link = e.target.value;
                      setFormData({ ...formData, additionalButtons: updated });
                    }}
                  />
                  <Button
                    onClick={() => {
                      const updated = formData.additionalButtons.filter((_, i) => i !== index);
                      setFormData({ ...formData, additionalButtons: updated });
                    }}
                    color="red"
                    className="text-sm"
                  >
                    Butonu Kaldır
                  </Button>
                </div>
              ))}
            </div>

            <div className="space-y-2">
              <FormInput
                placeholder="Arkaplan Resmi URL"
                value={formData.backgroundImage}
                onChange={(e) => setFormData({ ...formData, backgroundImage: e.target.value })}
              />
              {formData.backgroundImage && (
                <div className="relative h-32 rounded-lg overflow-hidden">
                  <img 
                    src={formData.backgroundImage} 
                    alt="Arkaplan önizleme" 
                    className="w-full h-full object-cover"
                  />
                  <div className="absolute inset-0 bg-black/40" />
                </div>
              )}
            </div>

            <FormInput
              placeholder="Website URL *"
              value={formData.website?.url}
              onChange={(e) => setFormData({
                ...formData,
                website: { ...formData.website, url: e.target.value }
              })}
              required
            />

            <div className="flex items-center gap-2">
              <input
                type="checkbox"
                checked={formData.website?.status}
                onChange={(e) => setFormData({
                  ...formData,
                  website: { ...formData.website, status: e.target.checked }
                })}
                className="w-5 h-5 rounded border-white/10 bg-black/20"
              />
              <span className="text-white/80">Website Aktif</span>
            </div>

            <select
          value={formData.category} // Değeri direkt formData'dan al
          onChange={(e) => setFormData({ ...formData, category: e.target.value })}
          className="w-full bg-black/20 rounded-lg p-3 text-white border border-white/10"
          required
        >
          <option value="">Kategori Seçin *</option>
          {categories
            .filter(c => !c.isParent)
            .map(category => (
              <option key={category._id} value={category._id}>
                {category.name}
              </option>
            ))}
        </select>

            <div className="flex gap-2">
              <Button type="submit" color="blue">Kaydet</Button>
              <Button onClick={onClose} color="gray">İptal</Button>
            </div>
          </form>
        </div>
      </div>
    );
  };

  // Render app list
  const renderApps = () => (
    <div className="space-y-2">
      {apps.map(app => (
        <div key={app._id} className="flex justify-between items-center p-3 bg-black/20 rounded-lg">
          <div className="flex items-center gap-3">
            {app.logo && (
              <img 
                src={app.logo} 
                alt={app.name}
                className="w-6 h-6 object-cover rounded"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = '';
                }}
              />
            )}
            <div>
              <span className="text-white/90">{app.name}</span>
              <div className="text-white/40 text-sm">
                {app.website?.url}
              </div>
            </div>
          </div>
          <div className="flex items-center gap-2">
            <button 
              onClick={() => setEditingApp(app)}
              className="p-1.5 text-blue-400 hover:text-blue-300 hover:bg-white/5 rounded-lg transition-colors"
            >
              <Edit size={16} />
            </button>
            <button 
              onClick={() => handleDelete(app._id, 'apps')}
              className="p-1.5 text-red-500 hover:text-red-400 hover:bg-white/5 rounded-lg transition-colors"
            >
              <Trash2 size={16} />
            </button>
          </div>
        </div>
      ))}
    </div>
  );

  if (!isOpen) return null;

  return (
    <>
      <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50">
        <div className="bg-[#1a1c30] rounded-xl p-6 w-[800px] h-[80vh] overflow-y-auto shadow-xl border border-white/10 relative">
          <button
            onClick={onClose}
            className="absolute top-4 right-4 text-white/60 hover:text-white/80 transition-colors"
          >
            <X size={24} />
          </button>

          <div className="mb-6 flex justify-between items-center">
            <h2 className="text-white/90 text-xl">Admin Panel</h2>
            <Button onClick={onLogout} color="red">Çıkış Yap</Button>
          </div>

          <div className="space-y-8">
            {editingCategory && renderCategoryForm(true)}
            {renderCategoryForm()}
            {renderAppForm()}

            <div className="grid grid-cols-2 gap-6">
              <div>
                <h3 className="text-white/80 text-lg mb-4">Mevcut Kategoriler</h3>
                {renderCategories()}
              </div>
              <div>
                <h3 className="text-white/80 text-lg mb-4">Mevcut Uygulamalar</h3>
                {renderApps()}
              </div>
            </div>
          </div>
        </div>
      </div>

      {editingApp && (
      <AppEditModal
        app={editingApp}
        onClose={() => setEditingApp(null)}
        onSave={() => {
          fetchData();
          setEditingApp(null);
        }}
      />
    )}

    <Toast 
      isVisible={toast.show} 
      message={toast.message}
      type={toast.type}
    />
  </>
  );
};

export default AdminPanel;